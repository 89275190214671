.header {
  padding: 0 20px;
  @media screen and (max-width: 576px) {
    padding: 0;
  }
  .toggleBtn {
    background-color: #b31017;
    border: 1px solid #b31017;
    border-radius: 100%;
    height: 25px;
    width: 25px;
    position: absolute;
    left: -13px;
    z-index: 999;
    @media screen and (max-width: 576px) {
      display: none;
    }
   svg {
    fill: #fff;
    font-size: 22px;
    font-weight: 800;
   }
  }
  .user {
    padding-right: 10px;
    .userImg {
      height: 34px;
      width: 34px;
      border-radius: 100%;
    }
    .userInfo {
      span {
        font-size: 12px;
        font-weight: 400;
        color: #a5a6b4;
        display: block;
        margin-bottom: 5px;
        @media screen and (max-width: 576px) {
          font-size: 10px;
        }
      }
      p {
        font-size: 14px;
        font-weight: 400;
        color: var(--txt);
        margin: 0;
        @media screen and (max-width: 576px) {
          font-size: 10px;
        }
      }
    }
  }
  .walletOuter {
    // border-right: 1px solid #66677880;
    // border-left: 1px solid #66677880;
    @media screen and (max-width: 576px) {
      border-left: 0;
    }
    .walletBtn {
      background-color: #b31017;
      border: 1px solid #b31017;
      border-radius: 10px;
      height: 36px;
      font-size: 12px;
      font-weight: 500;
      color: #fff;
      padding: 14px;
      // margin: 0 20px;
      @media screen and (max-width: 576px) {
        height: 20px;
        width: 30px;
        border-radius: 5px;
        padding: 0;
        display: flex !important;
        justify-content: center;
        align-items: center;
        font-size: 10px;
      }
      img {
        @media screen and (max-width: 576px) {
         height: 14px;
        }
      }
      span {
        @media screen and (max-width: 576px) {
          display: none;
        }
      }
    }
    .disconnect {
      @media screen and (max-width: 576px) {
        width: 100%;
      }
    }
  }
  .toggleMode {
    background-color: transparent;
    padding: 5px 10px;
    border: none;
    @media screen and (max-width: 576px) {
      padding: 0;
    }
    svg {
      @media screen and (max-width: 576px) {
       height: 16px;
      }
    }
    .sunIcon {
      color: #fff;
      font-size: 20px;
    }
  }
  :global {
    .ant-input {
      height: 38px;
      width: 322px;
      border: 2.5px solid var(--inputBg);
      background: var(--inputBg);
      color: var(--txt);
    }
    .ant-input::placeholder {
      color: #7e7e8b !important;
    }
    .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
      background-color: #b31017;
    border: 1px solid #b31017;
    }
  }
}
