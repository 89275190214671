.user {
  background: url("../../assets/images/section/bgLight.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  background-color: var(--bg);
  height: 100%;
  background-attachment: fixed;
  // height: 100vh;
  line-height: normal;
  padding: 110px 20px 30px;
  color: var(--txt);
  position: relative;
  @media screen and (max-width: 991px) {
    height: 100%;
  }
  @media screen and (max-width: 575px) {
    padding-left: 0;
    padding-right: 0;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--overlayBG);
    opacity: 0.85;
    z-index: 1;
  }
  .heading {
    position: relative;
    z-index: 9;
    display: flex;
    align-items: center;
    gap: 5px;
    padding-left: 15px;
    margin-bottom: 20px;
    p {
      color: #e09200;
      font-size: 16px;
      font-weight: 400;
    }
  }
  .card {
    position: relative;
    z-index: 9;
    margin: 0 15px;
    background-color: var(--cardBg);
    border-radius: 12px;
    padding: 20px 40px;
    margin-bottom: 22px;
    @media screen and (max-width: 767px) {
      padding: 30px;
    }
    @media screen and (max-width: 400px) {
      padding: 20px 10px;
    }
    .list {
      display: flex;
      gap: 13px;
      padding: 20px 0;
      border-bottom: 1px solid #252730;
      img {
        align-self: flex-start;
        height: 38px;
        width: 38px;
        border-radius: 100%;
      }
      .name {
        font-size: 14px;
        font-weight: 600;
        color: var(--txt);
        margin-bottom: 3px;
      }
      .userName {
        font-size: 12px;
        font-weight: 400;
        color: #7e7e8b;
      }
      .metaInfo {
        display: flex;
        align-items: center;
        margin: 5px 0;
        .userName {
          padding-right: 5px;
        }
      }
      .enable {
        background-color: #B31017;
        border-radius: 4px;
        padding: 7px 24px;
        border: 1px solid #B31017;
        color: #FFFFFF;
        font-size: 12px;
        font-weight: 500;
      }
      .disable {
        background-color: #323232;
        border-radius: 4px;
        padding: 7px 24px;
        border: 1px solid #4E5462;
        color: #FFFFFF;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
}
