.bot {
  background: url("../../assets/images/section/bgLight.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  background-color: var(--bg);
  height: 100%;
  background-attachment: fixed;
//   height: 100vh;
  line-height: normal;
  padding: 110px 20px 30px;
  color: var(--txt);
  position: relative;
  @media screen and (max-width: 575px) {
    padding-left: 0;
    padding-right: 0;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--overlayBG);
    opacity: 0.85;
    z-index: 1;
  }
  .heading {
    position: relative;
    z-index: 9;
    display: flex;
    align-items: center;
    gap: 5px;
    padding-left: 15px;
    margin-bottom: 20px;
    p {
      color: #e09200;
      font-size: 16px;
      font-weight: 400;
    }
  }
  .card {
    position: relative;
    z-index: 9;
    margin: 0 15px;
    background-color: var(--cardBg);
    border-radius: 12px;
    padding: 60px 40px;
    margin-bottom: 22px;
    @media screen and (max-width: 575px) {
      padding: 10px;
    }
    .address {
      span {
        color: #b3b3b3;
        font-size: 14px;
        font-weight: 400;
        @media screen and (max-width: 576px) {
          font-size: 12px;
        }
      }
    }
    .amount {
      @media screen and (max-width: 576px) {
        flex-direction: column;
      }
      h3 {
        color: var(--txt);
        font-size: 30px;
        font-weight: 400;
        margin: 5px 0 0 0;
        @media screen and (max-width: 576px) {
          font-size: 20px;
        }
      }
      span {
        color: #05af5a;
        font-size: 14px;
        font-weight: 400;
        align-self: flex-end;
        padding-bottom: 5px;
        @media screen and (max-width: 576px) {
          font-size: 12px;
          text-align: center;
        }
      }
    }
    .data {
        color: #B3B3B3;
        font-size: 14px;
        font-weight: 400;
        @media screen and (max-width: 576px) {
          font-size: 12px;
          text-align: center;
        }
    }
    .bg {
        background-color: var(--botBg);
        padding: 20px;
        @media screen and (max-width: 576px) {
          padding: 10px;
        }
    }
    .label {
        color: #949494;
        font-size: 12px;
        font-weight: 400;
    }
    .txt {
        color: var(--txt);
        font-size: 12px;
        font-weight: 400;
    }
    .poolContainer {
        border: 1px solid #A4A4A4;
        border-radius: 6px;
    }
    .tile {
        background-color: var(--tile);
        color: #A5A5A5;
        font-size: 10px;
        font-weight: 400;
        width: 110px;
        text-align: center;
        border-radius: 5px 0 0 0;
        padding: 5px 0;
    }
    .tabsOuter {
      @media screen and (max-width: 400px) {
        flex-direction: column;
      }
    }
    .infoTab {
      background-color: var(--tile);
        color: #A5A5A5;
        font-size: 12px;
        font-weight: 400;
        text-align: center;
        border-radius: 5px;
        padding: 10px 15px;
        @media screen and (max-width: 400px) {
          width: 100%;
        }
    }
    .swap {
      @media screen and (max-width: 400px) {
        flex-direction: column;
        gap: 5px;
      }
    }
    .userName {
      text-decoration: underline;
      text-underline-offset: 4px;
    }
  }
}
