.layout {
  .sider {
    position: fixed;
    left: 0;
    z-index: 1;
    height: 100vh;
  }
  .headerOuter {
    position: fixed;
    z-index: 100;
  }
}
