
@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: light;
}
@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter-Medium.ttf') format('truetype');
    font-weight: 600;
    font-style: medium;
}
@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: bold;
}
@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter-Bold.ttf') format('truetype');
    font-weight: 800;
    font-style: bolder;
}