.funds {
  background: url("../../assets/images/section/bgLight.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  background-color: var(--bg);
  // height: 100%;
  background-attachment: fixed;
  height: 100vh;
  line-height: normal;
  padding: 110px 20px 30px;
  color: var(--txt);
  position: relative;
  @media screen and (max-width: 991px) {
    height: 100%;
  }
  @media screen and (max-width: 575px) {
    padding-left: 0;
    padding-right: 0;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--overlayBG);
    opacity: 0.85;
    z-index: 1;
  }
  .heading {
    position: relative;
    z-index: 9;
    display: flex;
    align-items: center;
    gap: 5px;
    padding-left: 15px;
    margin-bottom: 20px;
    p {
      color: #e09200;
      font-size: 16px;
      font-weight: 400;
    }
  }
  .card {
    position: relative;
    z-index: 9;
    margin: 0 15px;
    background-color: var(--cardBg);
    border-radius: 12px;
    padding: 40px;
    margin-bottom: 22px;
    @media screen and (max-width: 767px) {
      padding: 30px;
    }
    @media screen and (max-width: 400px) {
      padding: 20px 10px;
    }
  }
  .border_right {
    border-right: 1px solid var(--border);
    padding: 0 80px 0 0;
    @media screen and (max-width: 991px) {
      padding: 0;
      border-right: 0;
    }
  }
  .collectorCard {
    padding: 30px 50px;
    border-radius: 6px;
    background-color: var(--collectorBg);
    border: 1px solid var(--border400);
    @media screen and (max-width: 576px) {
      padding: 5px;
    }
  }
  .collector {
    width: 100%;
    @media screen and (max-width: 767px) {
      padding: 0;
    }
  }
  .balance {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    span {
      color: #e09200;
    }
    @media screen and (max-width: 576px) {
      font-size: 16px;
    }
  }
  .label {
    color: #e09200;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 5px;
  }
  .submit {
    width: 85px;
    @media screen and (max-width: 576px) {
      width: 100%;
    }
  }
  :global {
    .ant-select {
      height: 40px;
      color: var(--txt);
      .ant-select-selector {
        background-color: var(--input);
        border: 1px solid #62636a !important;
        border-radius: 4px;
        color: var(--txt);
        height: 40px;
        font-size: 13px;
        font-weight: 500;
        font-family: "Inter", sans-serif;
      }
      .ant-select-arrow,
      .ant-select-selection-item {
        color: var(--txt);
      }
    }
  }
}
