.overview {
  position: relative;
  z-index: 9;
  background-color: var(--cardBg);
  border-radius: 12px;
  padding: 30px;
  @media screen and (max-width: 1199px) {
    padding: 15px;
  }
  @media screen and (max-width: 576px) {
    padding: 15px;
  }
  .btn {
    background-color: #b31017;
    border-radius: 4px;
    color: #fff;
    padding: 10px 20px;
    border: none;
    @media screen and (max-width: 576px) {
      width: 100%;
    }
  }
  .title {
    color: #e09200;
    font-size: 20px;
    font-weight: 400;
    margin: 0;
    @media screen and (max-width: 767px) {
      text-align: center;
    }
  }
  .card {
    padding: 30px 0;
    .title {
      font-size: 28px;
      font-weight: 600;
      color: var(--txt);
      margin: 0;
      @media screen and (max-width: 1199px) {
        font-size: 22px;
      }
    }
    .subtitle {
      font-size: 14px;
      font-weight: 400;
      color: #05AF5A;
      @media screen and (max-width: 1199px) {
        font-size: 12px;
      }
      @media screen and (max-width: 767px) {
        text-align: center;
      }
    }
  }
  .border_bottom {
    border-bottom: 1px solid var(--border);
  }
}
