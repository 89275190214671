.disableModal {
  .content {
    padding: 20px;
    p {
      color: var(--txt);
      font-size: 18px;
      font-weight: 400;
      text-align: center;
      margin-bottom: 30px;
    }
    .btn {
      height: 40px;
      width: 120px;
      border-radius: 6px;
      color: #fff;
    }
    .reject {
      background-color: #66677880;
      border: 1px solid #66677880;
    }
    .accept {
      background-color: #b31017;
      border: 1px solid #b31017;
    }
  }
}
